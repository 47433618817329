import React from 'react'
import { render } from 'react-dom'
import Highlight, { defaultProps } from 'prism-react-renderer'
import styled from 'styled-components'
// import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live'
// import theme from 'prism-react-renderer/themes/nightOwl'
import theme from 'prism-react-renderer/themes/nightOwl'

export const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;

  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
  }
`

export const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`

export const Code = ({ codeString, language, ...props }) => {
  if (props['react-live']) {
    return (
      <LiveProvider code={codeString} noInline={true}>
        <LiveEditor />
        <LiveError />
        <LivePreview />
      </LiveProvider>
    )
  } else {
    
    return (
      <Highlight {...defaultProps} theme={theme} code={codeString.trim()} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          // <pre className={className} line-numbers style={style}>
          <Pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                 <LineNo>{i + 1}</LineNo>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Pre>
        )}
      </Highlight>
    )
  }
}
