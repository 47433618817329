import "./src/css/style.css";

// require('typeface-merriweather');
// require('typeface-lora');
// require('typeface-aleo');
// require('typeface-pt-serif');
// require('typeface-noto-sans');
// require('typeface-noto-serif');
// require('typeface-lato');
// require('typeface-roboto');
// require('typeface-roboto-condensed');
// require('typeface-source-sans-pro');

// require('typeface-ibm-plex-sans');
// require('typeface-ibm-plex-serif');
// require('typeface-d-din');
// require('typeface-cooper-hewitt');
// require('typeface-rubik');
// require('typeface-droid-serif');
// require('typeface-ibm-plex-sans-condensed');
// require('typeface-ibm-plex-mono');
// require('typeface-bebas-neue');

// require("prism-themes/themes/prism-atom-dark.css");
// require("prismjs/plugins/line-numbers/prism-line-numbers.css");

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
      import(`intersection-observer`)
      console.log(`# IntersectionObserver is polyfilled!`)
    }
  }

import { wrapRootElement as wrap } from './wrap-root-element'

export const wrapRootElement = wrap